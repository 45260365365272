<template>
    <div>
        <b-form class="px-lg-4 px-2" @submit="onSubmit">
            <b-row class="my-2 px-4">
                <b-col cols="12" class="text-center text-lg-left">
                    <b-button variant="outline-info" size="lg" class="font-weight-bold w-100">立即免費諮詢</b-button>
                </b-col>
            </b-row>

            <b-row class="mt-3 mb-2 px-4">
                <b-col cols="12">
                    <b-form-input name="name"
                                  v-model="form.name"
                                  placeholder="請填寫您的姓名"
                                  required/>
                </b-col>
            </b-row>

            <b-row class="my-2 px-4">
                <b-col cols="12" class="text-center">
                    <b-form-input
                        name="tel"
                        v-model="form.line"
                        placeholder="請填寫您的電話或 LINE ID"
                        required/>
                </b-col>
            </b-row>

            <!-- 諮詢按鈕 -->
            <b-row class="justify-content-center mt-3">
                <b-col cols="12" class="text-center">
                    <b-button variant="warning" size="lg" type="submit"
                              class="text-white font-weight-bold mx-2 col-5 py-1 font-lg">
                        送出
                    </b-button>
                </b-col>

                <b-col cols="12 text-uppercase text-center font-weight-bold">
                    or
                </b-col>

                <b-col cols="12" class="text-center">
                    <b-link :to="{ path: 'FormConsultation' }" class="text-dark font-weight-bolder">
                        <u>表單諮詢</u>
                    </b-link>
                </b-col>
            </b-row>
        </b-form>

        <b-modal :id="modal.id" :title="modal.title" hide-footer>
            <p class="my-4" v-text="modal.content"></p>
            <b-button @click="$bvModal.hide(modal.id)">關閉</b-button>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "quickSubmit",
    data() {
        return {
            form: {
                name: '',
                line: '',
                sex: '',
                tel: '',
                questType: '',
            },
            options: {
                sex: [
                    {text: '先生', value: '先生'},
                    {text: '小姐', value: '小姐'},
                ],
                questType: [
                    {text: '汽車貸款', value: '1'},
                    {text: '房屋貸款', value: '2'},
                    {text: '信用貸款', value: '3'},
                    {text: '商品貸款', value: '4'},
                    {text: '其他貸款', value: '5'},
                ],
            },
            modal: {
                id: 'popup-message',
                title: '立即諮詢正在開發中，請稍後',
                content: '立即諮詢正在開發中，請稍後',
            }
        }
    },
    methods: {
        async onSubmit(event) {
            this.$emit('form-submit', 'parent');
            event.preventDefault();

            this.axios({
                method: 'POST',
                url: 'https://script.google.com/macros/s/AKfycbwHG6DRacRRfRAzPCs4bOAAlvWOsiQNDcxF-ItolOrGEya83elVNXuFkylH368_5gJf/exec',
                data: JSON.stringify({
                    type: '立即諮詢',
                    name: this.form.name,
                    line: this.form.line,
                }),
                mimeType: 'application/JSON'
            }).then(() => {
                this.modal.title = '傳送成功';
                this.modal.content = '已收到您的諮詢請求，已將訊息登入至系統，我們將會盡快與您聯絡';
                this.$bvModal.show(this.modal.id);
                event.target.reset();
            }).catch(err => {
                this.modal.title = '傳送失敗';
                this.modal.content = `請聯絡系統管理員。`;
                console.log(err);
                this.$bvModal.show(this.modal.id);
                event.target.reset();
            });
        }
    }
}
</script>

<style scoped>

</style>