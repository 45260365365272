import Vue from "vue"

/**
 * @description 紀錄使用者軌跡
 * @param {String} trackedTitle 頁面標題
 */
const pageUserTrack = async ({ trackedTitle = '' } = {}) => {
  // 若無傳入參數或當下路由無 mate 資訊時會略過軌跡紀錄
  if (Vue.gtm && trackedTitle) {
    // 發送 content-view 事件給 GTM
    Vue.gtm.trackEvent({
      // 自行定義的事件名稱
      event: 'content-view',
      // 頁面功能/分頁標題文字
      pageTitle: trackedTitle
    })
  }

}

export default pageUserTrack;