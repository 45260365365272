import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import trackService from "@/services/trackService";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {trackTitle: '首頁'}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {trackTitle: '關於我們'}
  },
  {
    path: '/ServiceFeatures',
    name: 'ServiceFeatures',
    component: () => import('../components/ServiceFeatures.vue'),
    meta: {trackTitle: '服務特色'}
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import('../components/ContactUs.vue'),
    meta: {trackTitle: '聯絡我們'}
  },
  {
    path: '/CommonProblem',
    name: 'CommonProblem',
    component: () => import('../components/CommonProblem.vue'),
    meta: {trackTitle: '常見問題'}
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../components/AboutUs.vue'),
    meta: {trackTitle: '關於我們'}
  },
  {
    path: '/LoanProgramWebpage',
    name: 'LoanProgramWebpage',
    component: () => import('../components/LoanProgramWebpage.vue'),
    props: true,
    meta: {trackTitle: '速可貸方案'}
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {trackTitle: '登入'},
  },
  {
    path: '/Setting',
    name: 'Setting',
    component: () => import('../views/Setting.vue'),
    meta: {trackTitle: '設定'},
    children: [

      {
        path: 'imageChange',
        name: 'imageChange',
        component: () => import('../components/imageChange.vue'),
        meta: {trackTitle: '後台維護 - 圖片維護'},
      },
      {
        path: 'Connection',
        name: 'Connection',
        component: () => import('../components/Connection.vue'),
        meta: {trackTitle: '後台維護 - 誰聯絡我'},
      },
      {
        path: 'formData',
        name: 'formData',
        component: () => import('../components/formData.vue'),
        meta: {trackTitle: '後台維護 - 表單'},
      },
      {
        path: 'Count',
        name: 'Count',
        component: () => import('../components/Count.vue'),
        meta: {trackTitle: '後台維護 -  來站人次'},
      },
      {
        path: 'Logout',
        name: 'Logout',
        component: () => import('../components/Logout.vue'),
      }
    ]
  },
  {
    path: '/FormConsultation',
    name: 'FormConsultation',
    component: () => import('../components/FormConsultation.vue'),
    meta: {trackTitle: '表單諮詢'},
  },
  {
    path: '/Thanks',
    name: 'Thanks',
    component: () => import('../components/thanks.vue'),
    meta: {trackTitle: '表單諮詢填寫完成'},
  },

  {path: '/Setting/About', redirect: {name: 'About'}},
  {path: '/Setting/ServiceFeatures', redirect: {name: 'ServiceFeatures'}},
  {path: '/Setting/ContactUs', redirect: {name: 'ContactUs'}},
  {path: '/Setting/CommonProblem', redirect: {name: 'CommonProblem'}},
  {path: '/Setting/AboutUs', redirect: {name: 'AboutUs'}},
  {path: '/Setting/LoanProgramWebpage', redirect: {name: 'LoanProgramWebpage'}},
  {path: '/Setting/Login', redirect: {name: 'Login'}},
  {path: '/Setting/Setting', redirect: {name: 'Setting'}},

]

const router = new VueRouter({
  routes
})

router.afterEach(route => {
  setTimeout(() => {
    let pageTitle = route.meta.trackTitle || `${route.name} 未定義追蹤名稱`;
    trackService({trackedTitle: pageTitle})
  }, 500)
})

export default router
