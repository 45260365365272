<template>
    <b-carousel
        :id="carouselName"
        v-model="slide"
        :interval="5000"
        controls
        indicators
        background="var(--sukudai-green)"
        img-width="1024"
        img-height="480"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
    >
        <b-carousel-slide v-for="(img, index) in images" :key="index"
                          :img-src="`${testUrl}${img}`"
                          style="max-height: 300px;"
        ></b-carousel-slide>
    </b-carousel>
</template>

<script>
export default {
    name: "ImageCarousel",
    props: {
        carouselName: String,
    },
    data() {
        return {
            slide: 0,
            sliding: null,
            text: '',
            text2: "This is some text.\nIt is read only and doesn't look like an input.",
            images: [],
            // TODO 這裡要綁成產品時要移除
            // testUrl: 'http://34.81.255.46'
            testUrl: ''
        }
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
    },
    async created() {
        let response = await this.axios.get(`${this.GLOBAL.serverSrc}images.php?page=${this.carouselName}`);
        let data = response.data;
        if (data.status) {
            this.images = data.data;
        }
    }
}
</script>

<style scoped>

</style>