import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/fontawesome'
import './plugins/bootstrap-vue'
import './plugins/vue-fb-customer-chat'
import './plugins/setupVueGtm'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'
import internet from './components/internet';//參照檔案

Vue.config.productionTip = false
Vue.prototype.GLOBAL = internet//掛載到Vue範例上面

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
