<template>
    <div>
        <!-- 區塊一 -->
        <div class="bg-sukudai-green">
            <b-container class="bv-example-row py-3">
                <b-row class="align-items-center">
                    <!-- 區塊一 左側圖片輪播 -->
                    <b-col cols="12" lg="7">
                        <ImageCarousel carousel-name="Home"/>
                    </b-col>

                    <!-- 區塊一 右側輸入匡 -->
                    <b-col cols="12" lg="5">
                        <div class="bg-sukudai-light-grey py-3 rounded">
                            <quick-submit/>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 區塊二 -->
        <div class="pb-3">
            <h1 class="text-center py-3 font-weight-bolder">速可貸方案</h1>
            <b-container class="bv-example-row text-center">
                <b-row class="justify-content-center">
                    <b-col class="ServiceItemsImg d-flex flex-column justify-content-between align-items-center mb-2 mb-lg-0">
                        <img src="../assets/img/car.jpg" alt="car" class="mb-3"/>
                        <b-button class="border-0 rounded-0 w-100 bg-sukudai-green font-weight-bold font-md"
                                  @click="$router.push({'name': 'LoanProgramWebpage', params: {loadType: 0}})">
                            汽機車借貸
                        </b-button>
                    </b-col>

                    <b-col class="ServiceItemsImg d-flex flex-column justify-content-between align-items-center mb-2 mb-lg-0">
                        <img src="../assets/img/house.jpg" alt="house" class="mb-3"/>
                        <b-button class="border-0 rounded-0 w-100 bg-sukudai-yellow font-weight-bold font-md"
                                  @click="$router.push({'name': 'LoanProgramWebpage', params: {loadType: 1}})">
                            房屋貸款
                        </b-button>
                    </b-col>

                    <b-col class="ServiceItemsImg d-flex flex-column justify-content-between align-items-center mb-2 mb-lg-0">
                        <img src="../assets/img/shopping.jpg" alt="shopping" class="mb-3"/>
                        <b-button class="border-0 rounded-0 w-100 bg-sukudai-green font-weight-bold font-md"
                                  @click="$router.push({'name': 'LoanProgramWebpage', params: {loadType: 2}})">
                            信用貸款
                        </b-button>
                    </b-col>

                    <b-col class="ServiceItemsImg d-flex flex-column justify-content-between align-items-center mb-2 mb-lg-0">
                        <img src="../assets/img/creditcard.jpg" alt="credit card" class="mb-3"/>
                        <b-button class="border-0 rounded-0 w-100 bg-sukudai-yellow font-weight-bold font-md"
                                  @click="$router.push({'name': 'LoanProgramWebpage', params: {loadType: 3}})">
                            商品貸款
                        </b-button>
                    </b-col>

                    <b-col class="ServiceItemsImg d-flex flex-column justify-content-between align-items-center mb-2 mb-lg-0">
                        <img src="../assets/img/businessloan.png" alt="business loan" class="mb-3"/>
                        <b-button class="border-0 rounded-0 w-100 bg-sukudai-green font-weight-bold font-md"
                                  @click="$router.push({'name': 'LoanProgramWebpage', params: {loadType: 4}})">
                            企業貸款
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 區塊三 -->
        <div class="bg-sukudai-green">
            <b-container class="bv-example-row">
                <b-row cols="12" class="p-2 justify-content-center">
                    <b-col cols="2" class="w-100"
                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                    <b-col cols="6">
                        <h2 class="text-white col-12 text-center font-weight-bolder">專業團隊</h2>
                    </b-col>
                    <b-col cols="2" class="w-100"
                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                </b-row>
                <b-row>
                    <!-- 第三區 左側 -->
                    <b-col cols="12" lg="6">
                        <div>
                            <ImageCarousel carousel-name="AboutTeam"/>
                        </div>
                    </b-col>

                    <!-- 第三區 右側 -->
                    <b-col cols="12" lg="6" class="mt-2 mt-lg-0">
                        <div class="bg-white rounded p-3">
                            <div class="border-left border-dark d-flex flex-column pl-3">
                                <span class="px-2 py-1 mb-2 bg-sukudai-info font-weight-bold w-fit-content font-lg">
                                    專業團隊
                                </span>
                                <span>
                                    我們是一群擁有金融與法務專業經驗的理財團隊，不管是汽機車借貸、房貸/二胎、信貸、代書、企業貸款、商品貸款、債務整合等，都可以為您個人規劃專案。<br/>
                                    速可貸秉持深度顧問式整合服務，根據客戶的資金需求，從需求診斷、專屬顧問、客製規劃及後續需求資源整合，協助客戶迅速取得資金。<br/>
                                    專業誠信、永續經營的理念以陪我們走過10個年頭，已成功幫助上萬位客戶解決資金問題。
                                </span>
                            </div>
                        </div>
                    </b-col>

                    <!-- 第三區 下方 -->
                    <b-col cols="12" class="my-3">
                        <WeHelp/>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- 客戶心得 -->
        <comment/>
    </div>
</template>

<script>
import comment from "../components/comment";
import WeHelp from "../components/WeHelp";
import ImageCarousel from "../components/ImageCarousel";
import quickSubmit from "../components/quickSubmit";

export default {
    components: {
        comment,
        WeHelp,
        ImageCarousel,
        quickSubmit,
    },

    data() {
        return {
            sliding: null,
            text: '',
            text2: "This is some text.\nIt is read only and doesn't look like an input.",
        }
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
    },
}
</script>

<style lang="scss" scoped>

.ServiceItemsImg {
    &:not(:last-child) {
        border-right: 1px solid transparent;
    }

    img {
        width: 100%;
        height: 130px;
        object-fit: cover;
    }

    flex-basis: 100%;
    flex-grow: 0;

    @media (min-width: 576px) {
        flex-basis: 50%;
    }
    @media (min-width: 768px) {
        flex-basis: calc(100% / 3);
    }

    @media(min-width: 990px) {
        flex-basis: 20%;
        &:not(:last-child) {
            border-right: 1px solid #9e9e9e;
        }
    }
}

</style>
