<script>
//正式機
const serverSrc='/api/';

//測試機
const serverSrc_tset='/api/';

  export default
  {
    serverSrc,//正式機API位置
    serverSrc_tset,//測試機API位置
  }
</script>