<template>
    <div class="py-3 text-center">
        <div class="mb-4 text-white row justify-content-center">
            <span class="col-6 col-lg-4 pb-3 "
                  style="border-bottom: 1px solid #fff; font-size: 2em; font-weight: bolder">我們已協助</span>
        </div>

        <div>
            <b-container class="bv-example-row text-center">
                <b-row class="justify-content-center">
                    <b-col cols="6" lg="3">
                        <b-button class="border-0 rounded-0 w-100 py-1 font-lg font-weight-bolder bg-sukudai-title-info">
                            汽機車借貸
                        </b-button>
                        <p class="text-white font-xxl">
                            <ICountUp
                                :delay="delay[0]"
                                :endVal="endVal[0]"
                                :options="options"
                            />
                        </p>
                    </b-col>

                    <b-col cols="6" lg="3">
                        <b-button class="border-0 rounded-0 w-100 py-1 font-lg font-weight-bolder bg-sukudai-yellow">
                            房屋貸款
                        </b-button>
                        <p class="text-white font-xxl">
                            <ICountUp
                                :delay="delay[1]"
                                :endVal="endVal[1]"
                                :options="options"
                            />
                        </p>
                    </b-col>

                    <b-col cols="6" lg="3">
                        <b-button class="border-0 rounded-0 w-100 py-1 px-4 font-lg font-weight-bolder bg-sukudai-title-info">
                            信用貸款
                        </b-button>
                        <p class="text-white font-xxl">
                            <ICountUp
                                :delay="delay[2]"
                                :endVal="endVal[2]"
                                :options="options"
                            />
                        </p>
                    </b-col>

                    <b-col cols="6" lg="3">
                        <b-button class="border-0 rounded-0 w-100 py-1 font-lg font-weight-bolder bg-sukudai-yellow">
                            商品貸款
                        </b-button>
                        <p class="text-white font-xxl">
                            <ICountUp
                                :delay="delay[3]"
                                :endVal="endVal[3]"
                                :options="options"
                            />
                        </p>
                    </b-col>

<!--                    <b-col>-->
<!--                        <b-button class="border-0 rounded-0 w-100 py-1 font-lg font-weight-bolder bg-sukudai-title-info">-->
<!--                            企業貸款-->
<!--                        </b-button>-->
<!--                        <p class="text-white font-xxl">-->
<!--                            <ICountUp-->
<!--                                :delay="delay[4]"-->
<!--                                :endVal="endVal[4]"-->
<!--                                :options="options"-->
<!--                            />-->
<!--                        </p>-->
<!--                    </b-col>-->
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {
    name: "WeHelp",
    components: {
        ICountUp,
    },
    data() {
        return {
            // count-up 屬性
            delay: [
                this.random_number(),
                this.random_number() * 1.25,
                this.random_number() * 1.5,
                this.random_number() * 1.75,
                this.random_number() * 0.75,
            ],
            endVal: [
                651,
                172,
                813,
                922,
                29,
            ],
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
        }
    },
    methods: {
        onReady: function (instance) {
            instance.update(999);
        },
        random_number() {
            return Math.round(Math.random() * 1000);
        },
    }
}
</script>

<style scoped>

</style>