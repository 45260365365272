<template>
    <div class="pt-4 pb-2 text-center">
        <h2>
            <span class="px-4 font-weight-bold" style="background: linear-gradient(transparent 80%, var(--sukudai-title-info) 80%);">
                成功核貸客戶心得
            </span>
        </h2>
        <carousel :perPage="carouselPerPage" :autoplay="true" :autoplayTimeout="10000"
                  :centerMode="true" :loop="true" :navigationEnabled="true"
                  class="mt-2">
            <slide class="p-2" v-for="(item, key) in comment" :key="key">
                <b-card tag="article" class="border-0">
                    <template>
                        <div class="row justify-content-center text-center carousel-cards" :class="borderColor(key)">
                            <div class="col-11 border rounded p-3 pt-5 mt-5" :id="`slide-${key}`">
                                <p class="p-0 font-weight-bold font-md">{{ item.customer }}</p>
                                <span>{{ item.said }}</span>
                            </div>

                            <img v-if="key === 0" src="../assets/img/male_1.jpg" style="width: 80px; height: 80px" alt="" class="rounded-circle border position-absolute">
                            <img v-if="key === 1" src="../assets/img/female_1.jpg" style="width: 80px; height: 80px" alt="" class="rounded-circle border position-absolute">
                            <img v-if="key === 2" src="../assets/img/male_2.jpg" style="width: 80px; height: 80px" alt="" class="rounded-circle border position-absolute">
                            <img v-if="key === 3" src="../assets/img/female_icon.png" style="width: 80px; height: 80px" alt="" class="rounded-circle border position-absolute">
                            <img v-if="key === 4" src="../assets/img/male_icon.png" style="width: 80px; height: 80px" alt="" class="rounded-circle border position-absolute">
                        </div>
                    </template>
                </b-card>
            </slide>
        </carousel>
    </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
    name: "comment",
    components: {
        Carousel,
        Slide
    },
    data() {
        return {
            carouselPerPage: this.renewCarouselPerPage(),
            comment: [
                {
                    customer: "羅先生",
                    said: "謝謝速可貸，速可貸真的很好借，本人因信用瑕疵為不好借款，透過蔡專員協助規劃週轉資金，度過疫情難關!!",
                    img: '../assets/img/male_1.jpg',
                },
                {
                    customer: "蘇小姐",
                    said: "透過朋友介紹前往詢問，富無態度真的很好，放款速度也十分快速，的確是很方便也不囉嗦，而且不收諮詢費，真的很貼心。",
                    img: '../assets/img/female_1.jpg',
                },
                {
                    customer: "王先生",
                    said: "謝謝貴公司的信任，本來有欠地下錢莊一筆錢，謝謝鄭小姐幫我處理債務整合，近期壓力變小，也有更多時間可以陪家人，感恩。",
                    img: '../assets/img/male_2.jpg',
                },
                {
                    customer: "葉小姐",
                    said: "最近牽了一台二手CR-V，第二次找上速可貸協助規劃車貸，比車行的方案好，真的很謝謝~",
                    img: '../assets/img/female_icon.png',
                },
                {
                    customer: "侯先生",
                    said: "近期因疫情失業，房貸無法如期繳款又忙於尋找工作，造成巨大的經濟壓力，貴公司協助貸款不僅補上資金缺口，也讓每月壓力減小了",
                    img: '../assets/img/male_icon.png',
                },
            ]
        }
    },

    methods: {
        onResize() {
            this.carouselPerPage = this.renewCarouselPerPage();
        },

        renewCarouselPerPage() {
            return (window.innerWidth < 900) ? 1 : 3;
        },

        borderColor(index) {
            return index % 2 ? 'yellow' : 'green';
        }
    },

    mounted() {
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style lang="scss" scoped>
    .green {
        $a: .2em solid var(--sukudai-green) !important;
        div {border: $a;}
        img { border: $a; }
    }

    .yellow {
        $a: .2em solid var(--sukudai-yellow) !important;
        div {border: $a;}
        img { border: $a; }
    }

    img {
        background: #fff;
    }
</style>