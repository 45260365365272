<template>
    <b-container>
        <!-- 上方navbar -->
        <b-navbar toggleable="md" class="navbar navbar-light text-center">
            <b-navbar-brand :to="{ path: '/' }">
                <b-img :src="require('../src/assets/icon/logo/logo.png')" width="200%" height="55%"/>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-2 ml-lg-4">
                    <b-nav-item :to="{ path: 'AboutUs' }">關於我們</b-nav-item>
                    <b-nav-item :to="{ path: 'LoanProgramWebpage' }">速可貸方案</b-nav-item>
                    <b-nav-item :to="{ path: 'ServiceFeatures' }">服務特色</b-nav-item>
                    <b-nav-item :to="{ path: 'CommonProblem' }">常見問題</b-nav-item>
                    <b-nav-item :to="{ path: 'ContactUs' }">聯絡我們</b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav v-show="login" class="ml-auto">
                    <b-nav-item :to="{ path: 'Login' }">登入</b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav v-show="setting" class="ml-auto">
                    <b-nav-item :to="{ path: 'Setting' }">設定</b-nav-item>
                </b-navbar-nav>

            </b-collapse>
        </b-navbar>

        <!-- 中間顯示內容 -->
        <router-view></router-view>

        <!-- 下方footer -->
        <b-navbar toggleable="lg" type="dark" variant="light">
            <b-container class="bv-example-row mt-3 p-2">
                <b-row>
                    <b-col cols="12" lg="4">
                        <b-img :src="require('../src/assets/icon/logo/logo.png')" width="200%" height="55%" class="mb-2"/>
                        <p>
                            速可貸本著誠信、專業、服務的理念提供多項金融服務，並提供諮詢、輔導及規劃。<br/>
                            有別於傳統多維度的徵信模型與申貸管道，我們擁有更好的創新效率；採用最高層級的客戶個資保密措施，絕對保障客戶隱私。
                        </p>
                    </b-col>

                    <b-col cols="12" lg="4" class="row justify-content-center d-none d-lg-flex">
                        <div class="font-weight-bolder d-flex flex-column">
                            <router-link :to="{ path: 'AboutUs' }" class="text-dark mb-2">關於我們</router-link>
                            <router-link :to="{ path: 'LoanProgramWebpage' }" class="text-dark mb-2">速可貸方案</router-link>
                            <router-link :to="{ path: 'ServiceFeatures' }" class="text-dark mb-2">服務特色</router-link>
                            <router-link :to="{ path: 'CommonProblem' }" class="text-dark mb-2">常見問題</router-link>
                            <router-link :to="{ path: 'ContactUs' }" class="text-dark mb-2">聯絡我們</router-link>
                        </div>
                    </b-col>

                    <b-col cols="12" lg="4">
                        <div class="text-lg-center">
                            <h3 class="font-weight-bolder">速可貸 SUPERPAY</h3>
                            <h6>各式貸款服務，秉持誠信專業服務</h6>
                            <h6>地址：510 彰化縣員林市惠明街 313 號</h6>
                            <h6>營業時間：上午 09:00 到晚上 09:00</h6>
                            <h6>聯絡電話：0800558166 或 0983212666</h6>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-navbar>

        <div>
            <!-- Messenger 洽談外掛程式 Code -->

            <!-- Your 洽談外掛程式 code -->
            <div id="fb-customer-chat" class="fb-customerchat">
            </div>
        </div>

        <b-row id="messenger" class="position-fixed m-3 flex-column-reverse">
            <div id="fb-root"></div>
<!--            <a class="p-3 rounded-circle icon-facebook" href="https://www.facebook.com/109195401861626/" target="_blank">-->
<!--                <img :src="require('@/assets/icon/logo/facebook-f-brands.svg')" alt="Facebook page" class="w-100 h-100">-->
<!--            </a>-->
            <a class="p-2 rounded-circle icon-line" href="https://line.me/R/ti/p/@359xbcpd" target="_blank">
                <img :src="require('@/assets/icon/logo/line.svg')" alt="Line" class="w-100">
                <div class="line-qrcode">
                    <img :src="require('@/assets/img/line_qrcode.png')" alt="Line QR code" class="w-100">
                </div>
            </a>
            <a type="button" class="p-3 rounded-circle icon-angle-up" v-b-modal.quick-submit>
                <img :src="require('@/assets/icon/logo/angle-up.svg')" alt="Line" class="w-100">
            </a>
        </b-row>

        <b-modal id="quick-submit" hide-footer hide-header>
            <quick-submit ref="form"/>
        </b-modal>
    </b-container>
</template>

<script>
import quickSubmit from "./components/quickSubmit";

export default {
    components: {
        quickSubmit
    },
    data() {
        return {
            setting: '',
            login: '',
        }
    },

    created: function () {
        if (sessionStorage.getItem('account') == null && sessionStorage.getItem('token') == null) {
            this.setting = false;
            this.login = true;
        } else {
            this.setting = true;
            this.login = false;
        }
    },

    methods: {
    },

}
</script>

<style lang="scss" scoped>
.nav-link {
    border-bottom: .3em solid transparent;
    margin: 0 .25em;

    &.router-link-active {
        border-bottom: .3em solid var(--sukudai-green);
        font-weight: 800;
    }
}


#messenger {
    $width: 60px;
    right: 5px;
    bottom: 72px;

    a {
        width: $width;
        height: $width;
        margin: .25em;
        cursor: pointer;
        user-select: none;
        //filter: brightness(.8);
        transition: .2s;

        //&:hover {
        //    filter: brightness(1);
        //}

        &.icon-facebook {
            background-color: #2374e1;
        }

        &.icon-line {
            background-color: #06c755;
            position: relative;

            >.line-qrcode {
                display: none;
                width: 150px;
                height: 150px;
                position: absolute;
                left: -170px;
                top: -30px;
                border-radius: 0.5em;
                padding: 0.5em;
                background: #fff;
                border: 1px solid #000c;
                opacity: 0;
                transition: .3s;

                //&:before {
                //    content: "";
                //    width: 10px;
                //    height: 10px;
                //    clip-path: polygon(100% 50%, 0 0, 0 100%);
                //    background-color: #fff;
                //    position: absolute;
                //    left: 100%;
                //    top: calc(50% - 5px);
                //}
            }

            &:hover > .line-qrcode {
                display: block;
                opacity: 1;
            }
        }

        &.icon-angle-up {
            background-color: #fff;
            box-sizing: border-box;
            border: .2em solid #000;
        }
    }
}
</style>

<style lang="scss">
:root {
    --sukudai-light-grey: #f4f4f4;
    --sukudai-grey: #9e9e9e;
    --sukudai-green: #28b09c;
    --sukudai-yellow: #ffb600;
    --sukudai-black: #1c1c1c;
    --sukudai-info: #78ffef;
    --sukudai-title-info: #98DEE1;
}

.bg-sukudai-green {
    background: var(--sukudai-green);
}

.bg-sukudai-info {
    background: var(--sukudai-info);
}

.bg-sukudai-title-info {
    background: var(--sukudai-title-info);
}

.bg-sukudai-light-grey {
    background: var(--sukudai-light-grey);
}

.bg-sukudai-yellow {
    background: var(--sukudai-yellow);
}

.w-fit-content {
    width: fit-content;
}

.font-xxl {font-size: 3em;}
.font-xl {font-size: 2em;}
.font-lg {font-size: 1.5em;}
.font-md {font-size: 1.2em;}
.font-sm {font-size: 1em;}


</style>
