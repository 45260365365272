import Vue from "vue";
import VueGtm from "vue-gtm";

Vue.use(VueGtm, {
  // container id
  id: 'GTM-PGGNCM7',
  // enable track or not (optional)
  enabled: process.env.NODE_ENV === 'production',
  // display console logs debugs or not (optional)
  debug: false
})
